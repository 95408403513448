import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb una olor que recorda un poc al pebrebó, presenta un capell de fins a 8 cm de diàmetre, convex, però prest torna pla, es clivella amb facilitat i amb un mamelló central no gaire alt acompanyat d’esquames negroses sobre un fons gris blanquinós. Les làmines de davall són blanques, amples, amb l’aresta moltes vegades tacada de puntets negres i les espores són blanques en massa, el·lipsoïdals, de 7-8 x 4-5,5 micres. El peu és rabassut, tan llarg com el diàmetre del capell, de color cendrós o blanquinós i amb petites esquames o quasi llis.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      